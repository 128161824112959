.image-container {
  /*background-color: #333;*/
  overflow: hidden;
  position: relative;
  margin: 0px auto;
  transition: backgroundColor 0.6s ease-in;
}


@media all and (max-width: 767px) {

#modalInner .content-status .image-container:before {
  content: 'LOADING';
  margin:0 auto;
  color:#fff;
  width:100%;
  display:block;
  text-align: center;
  letter-spacing: 5px;
  position:absolute;
  top:48%;
  left:0px;
  text-decoration:blink;
  opacity:.8;
}
}

.image-container-mobile {
  background-color: #ccc;
  overflow: hidden;
  position: relative;
  margin: 0px auto;
  transition: backgroundColor 0.6s ease-in;
}
.image {
  /*position: absolute;*/
  width: 100%;
  /*height: 100%;*/
  min-width:100%;
  opacity: 0;
  transition: opacity 1s ease-in;
}
/*.image.imgFirst {top:50px;}
.image.imgLast {padding-bottom:50px;background:#333;}*/


  .image.isLoaded.ison {
    transition-delay: 1s !important;
    opacity: 1 !important;
  }

  .image.isLoaded.isoff{
    transition-delay: .1s !important;
    opacity:0 !important;
  }


  .imgInfo {
      opacity: 0;
      filter: alpha(opacity=0);
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 13px;
      line-height: 1em;
      color: #fff;
      padding-left:20px;
      padding-top:15px;
      background: rgba(0,0,0,0);
  }

  @media all and (min-width: 768px) {
    .imgInfoFirst{
        top: 50px !important;
    }
  }
/*.imgInfoFirst{
    top: 50px !important;
}
.imgInfoInline,.imgInfoInLast{
  top:0px !important;
}
*/
  .coverBtn:hover .image-container .imgInfo{
    opacity:1;
    background: rgba(0,0,0,.3);
  }

  .wideImage{
    width:200%;
    max-width:200%;
    margin-left:-50%;
  }
