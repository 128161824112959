@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* ... */
}

:root {
  font-family: InterVariable, sans-serif;
  font-feature-settings: 'cv08' off, 'zero' on, 'dlig' on, 'calt' on, 'ss02' 0, 'ss03' on, 'ss04' on, 'cv05' on, 'cv06' on, 'ss03' on; /* fix for Chrome */
  transform-origin: top;
}

a{cursor:pointer!important;}

body{
  -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    margin: 0;
    overscroll-behavior: none;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-family: Inter, sans-serif;
    background:#fff;
}

img {
  max-width:100%;
}

nav {z-index: 10;
    background: transparent;
    backdrop-filter: blur(30px);
  }

  #main{
    max-width: 1300px;
      margin: 0 auto;
    }
  #projects{
    height:100vh;
    overflow: hidden;
    overflow-y: scroll;
    max-width: 1300px;
  }
  .magenta{
    color:#ee2585;
    background:linear-gradient(93deg,#ee2585 0%, #333 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .magenta-dark{
    color:#ad105f
  }
  .magenta-light{
    color:#f4cde0;
  }
  .bg-magenta{
    background-color:#e9266a;
  }
  .bg-magenta-dark{
      background-color:#ad105f;
  }

.fadeIn {
  animation: 1.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 0px) scale(1, 1);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px) scale(1, 1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px) scale(1, 1);
  }
  to {
    transform: translate(0, -0px)  scale(1, 1);
    opacity: 0;
  }
}

/* Work */
.zoomIn {
  animation: 1.2s zoomIn forwards;
  transform-origin: center;
}

@keyframes zoomIn {
  from {
    transform: scale(1.2, 1.2);
  }
  to {
    transform: scale(1.3, 1.3);
  }
}


.zoomOut {
  animation: 1.2s zoomOut forwards;
  transform-origin: center;
}

@keyframes zoomOut {
  from {
    transform: scale(1.3, 1.3);
  }
  to {
    transform: scale(1.2, 1.2);
  }
}

img.project_featured {
  height: 160vh;
  width: 100%;
  object-fit: cover;
  object-position: center top;
  min-width:100%;
}

/* animated grid */
.galleryScale {
  margin: 0;
  background-color: white;
}


.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  transition: transform 2s ease-in-out;
}
.column:nth-child(1){
  transform-origin: left;
}
.column:nth-child(2){
  position:relative;
  transform-origin: center top;
  overflow-y: scroll;
}
.column:nth-child(3){
  transform-origin: right;
}

.orange-column {
  background-color: orange;
  height: 100px;
}

.blue-column {
  background-color: blue;
  height: 100px;
}

.green-column {
  background-color: green;
  height: 100px;
}
/* discover */
.discover{
  transition: opacity 1s ease;
  background:#000;
  height:100vh;
}
.discover-false{
  opacity:0;
}
.discover-true{
  opacity:1;
}
.bg-pink {
  background-color: #ee2585;
}
.logoIcon{
  width:80px;
  padding:10px 0px;
}
.logoIconInline{
    width:80px;
    margin:0 auto;
    margin-top:60px;
}
.logoName{
  width: 260px;
    align-self: center;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3em;
    letter-spacing:3px;
    background:linear-gradient(90deg,#ee2585 0%, #333 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.nav,.navBtn {transition: transform 1s ease,height 1s ease;}
.nav-false{transform: translate(0,-150vh); overflow:hidden;}
.nav-true{transform: translate(0,0); height:100vh; overflow:hidden;}
.navBtn.open-false{
transform: rotate(45deg);
}
.navBtn.open-true{
transform: rotate(180deg);
}

.closeProject{
    position: fixed;
    left: -90px;
    width: 80px;
    top: 30%;
  }
  .openMenu {

  }

.pushOut{transition: transform 1s ease;}
.pushOut-true{transform: translate(0,-150vh)}
.pushOut-false{transform: translate(0,0)}
table {
    border: 0!important;
    margin-right: 0!important;
    width: 100%!important;
    min-width:100% !important;
    margin:0px !important
}
tr:nth-child(odd) {
    background: hsla(0,0%,100%,.009);
}

tr {
    border-bottom: 1px solid #222;
    padding: 10px 5px;
}
td {text-align: left;font-size:80%; width:50% !important;}

@media (min-width: 601px) {
.nav-false{transform: translate(0,0)}

}
@media (max-width: 728px) {
.nav-false{transform: translate(0,0)}
.nav-false{height:1px;}
.nav {z-index: 10;
    background: #fff;;
  }
nav a li {width:100%;text-align:center;padding-top:30px;}
nav a li span{font-size:160%;letter-spacing:3px;font-weight:400;text-transform:uppercase;}
}
